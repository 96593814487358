<template>
	<bs-box class="device-status">
		<bs-title title="设备状况" />
		<div class="top-modal">
			<img class="icon-status" :src="require(`@/assets/imgs/screen/${list[0].imgName}.png`)" />
			<p class="label">{{ list[0].title }}</p>
			<div class="item-right">
				<span class="number">{{ list[0].value || 0 }}</span>
				<em>台</em>
			</div>
		</div>
		<ul class="items">
			<li
				class="item"
				v-for="(item, index) in list.slice(1)"
				:key="index"
				:style="`cursor: ${item.link ? 'pointer' : 'text'}`"
				@click="showDeviceMarks(item)"
			>
				<img class="icon-status" :src="require(`@/assets/imgs/screen/${item.imgName}.png`)" />
				<div class="item-right">
					<p class="label">{{ item.title }}</p>
					<p>
						<span class="number">{{ item.value || 0 }}</span>
						{{ item.unit }}
					</p>
				</div>
			</li>
		</ul>
		<bs-title title="设备大类在线率" />
		<online-chart v-show="onlineChartData.length" :chart-data="onlineChartData" :first-category="firstCategory" />
		<gc-empty v-show="!onlineChartData.length" />
	</bs-box>
</template>

<script>
import EventBus from '@/utils/eventBus'
import BsTitle from '../components/BsTitle.vue'
import BsBox from '../components/BsBox.vue'
import OnlineChart from './RingChart.vue'
// import OnlineChart from "./PieChart2D.vue";
import { apiDeviceStatis, apiCategoryOnline } from '@/apis/home.api'

export default {
	name: 'deviceStatus',
	components: { BsTitle, BsBox, OnlineChart },
	props: {
		firstCategory: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			list: [
				{
					title: '设备总量',
					key: 'deviceCount',
					value: '',
					imgName: 'status1',
					unit: '台',
				},
				{
					title: '故障数量',
					key: 'faultyDeviceCount',
					value: '',
					imgName: 'status5',
					unit: '台',
					link: true,
					type: 1,
				},
				{
					title: '在线数量',
					key: 'onlineDeviceCount',
					value: '',
					imgName: 'status2',
					unit: '台',
				},
				{
					title: '超年限数量',
					key: 'overdueDeviceCount',
					value: '',
					imgName: 'status3',
					unit: '台',
				},
				{
					title: '离线数量',
					key: 'offlineDeviceCount',
					value: '',
					imgName: 'status4',
					unit: '台',
					link: false,
					type: 0,
				},
			],
			onlineChartData: [],
		}
	},
	methods: {
		getDeviceStatusData(firstCategory) {
			const params = {}
			if (firstCategory) params.firstCategory = firstCategory
			apiDeviceStatis(params)
				.then(res => {
					this.list = this.list.map(item => {
						return {
							...item,
							value: res[item.key],
						}
					})
				})
				.catch(err => {
					console.error(err)
				})
			apiCategoryOnline(params)
				.then(({ records = [] }) => {
					this.onlineChartData = records
				})
				.catch(err => {
					console.error(err)
				})
		},

		showDeviceMarks(item) {
			if (item.link) {
				EventBus.$emit('render-mark', {
					id: item.type,
					type: 0,
				})
			}
		},
	},
}
</script>
<style scoped lang="scss">
.device-status {
	position: absolute;
	top: 165px;
	bottom: 280px;
	left: 10px;
	width: 22%;
	max-width: 430px;
	background: $bs-bg;
	.top-modal {
		display: flex;
		align-items: center;
		margin-top: 20px;
		& > img {
			width: 46px;
		}
		.label {
			margin: 12px;
		}
		.item-right {
			margin-left: 20px;
			.number {
				font-size: 28px;
			}
			em {
				font-size: 12px;
			}
		}
	}
	.items {
		display: flex;
		flex-wrap: wrap;
		.item {
			margin-top: 32px;
			width: 50%;
			display: flex;
			align-items: center;
			.icon-status {
				width: 46px;
			}
			.item-right {
				margin-left: 10px;
				flex: 1;
				padding-right: 6px;
				.number {
					display: inline-block;
					margin-top: 10px;
					margin-right: 8px;
					font-size: 20px;
					font-family: DIN Alternate;
					font-weight: bold;
				}
			}
		}
	}
}
</style>
