<template>
	<div class="big-screen-wrapper" ref="containerRef">
		<div class="main-wrapper" ref="mainRef">
			<slot name="main"></slot>
		</div>
		<div class="layer-wrapper" ref="layerRef">
			<slot />
		</div>
	</div>
</template>
<script>
import ScaleLayout from './scaleLayout.js'
export default {
	props: {
		propName: {
			type: String,
			default: 'scale' + new Date().getTime(),
		},
		width: {
			type: Number,
			default: 1920,
		},
		height: {
			type: Number,
			default: 1080,
		},
		adpterClass: {
			type: String,
			default: 'apter-area',
		},
		resizeListenter: {
			type: Function,
			default: () => {
				return () => {}
			},
		},
	},
	data() {
		return {}
	},
	mounted() {
		this.$nextTick(() => {
			const container = document.body
			const mainEl = this.$refs['mainRef']
			const layerEl = this.$refs['layerRef']
			new ScaleLayout({
				context: this,
				propName: this.propName,
				width: this.width,
				height: this.height,
				container,
				mainEl,
				layerEl,
				adpterClass: this.adpterClass,
				resizeListenter: this.resizeListenter,
			})
		})
	},
}
</script>

<style lang="scss" scoped>
.big-screen-wrapper {
	width: 100%;
	height: 100vh;
	position: relative;
	background: #000;
	color: #fff;
	overflow: hidden;
	.main-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.layer-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		overflow: hidden;

		pointer-events: none; // 图层事件穿透

		* {
			// 其它事件恢复
			pointer-events: auto;
		}
	}
}
</style>
