<template>
	<div class="search">
		<el-autocomplete
			ref="autocomplete"
			v-model="searchKey"
			placeholder="请输入关键字进行检索"
			popper-class="item"
			:fetch-suggestions="querySearchAsync"
			@select="handleSelect"
			@keyup.enter.native.stop="handleSearch"
		>
			<el-select popper-class="bs-select" slot="prefix" v-model="searchType">
				<el-option label="设备编号" value="deviceNo"></el-option>
				<el-option label="地址" value="address"></el-option>
			</el-select>
			<i slot="suffix" class="icon-search el-icon-search" @click.stop="handleSearch"></i>
			<template slot-scope="{ item }">
				<span>{{ item.deviceNo || '--' }}</span>
				<span>{{ item.deviceTypeName || '--' }}</span>
				<span>{{ item.addressName || '--' }}</span>
			</template>
		</el-autocomplete>

		<ul class="drop-list" v-if="showDropList">
			<li class="item" v-for="(item, index) in deviceList" :key="index" @click.stop="handleSelect(item)">
				<span>{{ item.deviceNo }}</span>
				<span>{{ item.deviceTypeName }}</span>
				<span>{{ item.addressName }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { apiGetDeviceList } from '@/apis/account.api'

export default {
	name: 'Search',
	data() {
		return {
			searchType: 'deviceNo',
			searchKey: '',
			deviceList: [],
			showDropList: false,
		}
	},
	mounted() {
		window.addEventListener('click', this.handleOtherClick)
	},
	methods: {
		querySearchAsync(queryString, cb) {
			if (!queryString || queryString?.length < 2) return cb([])
			apiGetDeviceList({
				[this.searchType]: queryString,
			})
				.then(({ records = [] }) => {
					cb(records)
				})
				.catch(err => {
					console.log(err)
					cb([])
				})
		},
		handleSelect(item) {
			this.searchKey = item.deviceNo
			document.activeElement.blur()
			this.$emit('map-focus', item)
			this.showDropList = false
		},
		handleSearch() {
			if (!this.searchKey || this.searchKey?.length < 2) {
				this.$message.warning('搜索字符不能小于两位')
				return
			}
			this.$refs.autocomplete.activated = true
			this.$refs.autocomplete.getData(this.searchKey)
		},
		handleOtherClick() {
			this.showDropList = false
		},
	},
	destroy() {
		window.removeEventListener('click', this.handleOtherClick)
	},
}
</script>
<style scoped lang="scss">
.search {
	position: absolute;
	top: 97px;
	left: 27%;
	right: 27%;
	width: 46%;
	height: 50px;
	background: $bs-bg;
	border: 1px solid $bs-border-color;
	display: flex;
	align-items: center;
	::v-deep {
		.el-autocomplete {
			.el-input__inner {
				padding-left: 150px;
				padding-right: 50px;
			}
			.el-input__prefix .el-input__inner {
				padding-left: 15px;
				padding-right: 0;
			}
			.el-input__suffix {
				@include flex-center;
			}
		}
		.el-select {
			width: 130px;
			position: relative;
			&::after {
				content: '';
				height: 19px;
				border-right: 1px solid #fff;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.el-input__inner {
			background: none;
			border: none;
			color: #fff;
		}
	}

	.icon-search {
		cursor: pointer;
		font-size: 18px;
		padding-right: 15px;
	}
}
.drop-list {
	position: fixed;
	top: 155px;
	left: 27%;
	right: 27%;
	width: 46%;
	max-height: 300px;
	overflow: auto;
	background: $bs-bg;
	border: 1px solid $bs-border-color;
	padding: 8px 20px;
	.item {
		margin-top: 8px;
		height: 40px;
		background: rgba(124, 189, 255, 0.2);
		display: flex;
		align-items: center;
		font-size: 12px;
		padding: 0 20px;
		cursor: pointer;
		&:first-of-type {
			margin-top: 0;
		}
		span {
			&:nth-of-type(1) {
				flex: 1;
			}
			&:nth-of-type(2) {
				flex: 2;
				padding-left: 20px;
			}
			&:nth-of-type(3) {
				flex: 3;
				padding-left: 20px;
			}
		}
	}
}
</style>
