<template>
	<bs-box class="alarm-list">
		<bs-title title="告警列表" />
		<div class="box-table">
			<div class="tr">
				<div class="th" v-for="(item, index) in tableHeader" :key="index">
					{{ item }}
				</div>
			</div>
			<div class="table-content" v-if="tableData.length">
				<el-scrollbar>
					<div class="tr" v-for="(item, index) in tableData" :key="index" @click="handleJump(item)">
						<span class="td">{{ index + 1 }}</span>
						<span class="td">{{ nameTrans(item.userType, userType) }}</span>
						<span class="td">{{ item.username }}</span>
						<span class="td">{{ item.address }}</span>
						<span class="td">{{ item.deviceTypeName }}</span>
						<span class="td">{{ item.alarmName }}</span>
						<span class="td" v-html="getAlarmIcons(item.alarmLevel)"></span>
						<span class="td">{{ item.createTime }}</span>
						<span class="td">查看详情</span>
					</div>
				</el-scrollbar>
			</div>
			<gc-empty class="empty" v-else />
		</div>
	</bs-box>
</template>

<script>
import BsTitle from '../components/BsTitle.vue'
import BsBox from '../components/BsBox.vue'
import { apiGetAlarmRecordsList } from '@/apis/alarm.api'
import { userType } from '@/config/const'
import { nameTrans } from '@/utils'

export default {
	name: 'AlarmList',
	components: { BsTitle, BsBox },
	data() {
		return {
			tableHeader: [
				'序号',
				'客户类型',
				'客户/站点名称',
				'地址',
				'设备类型名称',
				'告警名称',
				'告警等级',
				'告警时间',
				'设备详情页',
			],
			tableData: [],
			userType,
		}
	},
	computed: {
		// 告警等级
		alarmLevel() {
			return this.$store.getters.dataList.alarmLevel || []
		},
	},
	methods: {
		nameTrans,
		getAlarmRecords(firstCategory) {
			this.firstCategory = firstCategory
			const params = {
				alarmSeq: 0,
				current: 1,
				size: 100,
			}
			if (firstCategory) params.firstCategory = firstCategory
			apiGetAlarmRecordsList(params)
				.then(({ records = [] }) => {
					this.tableData = records
				})
				.catch(err => {
					console.error(err)
				})
		},
		getAlarmIcons(level = 0) {
			let template = nameTrans(level, this.alarmLevel)
			for (let i = 0; i < level; i++) {
				template += "<i class='iconfont icon-caution'></i>"
			}
			return template || '--'
		},
		handleJump(item) {
			this.$emit('map-focus', { ...item, isFromAlarmList: true })
		},
	},
}
</script>
<style scoped lang="scss">
.gc-blank-page.empty {
	margin-top: 10px;
}
::v-deep .icon-caution {
	margin-left: 6px;
	font-size: 12px;
	color: #ec6b60;
}
.alarm-list {
	position: absolute;
	bottom: 10px;
	left: 10px;
	width: 76%;
	height: 258px;
	background: $bs-bg;
	.box-table {
		margin-top: 10px;
		font-size: 12px;
		.table-content {
			height: 140px;
			overflow: auto;
			.tr {
				background: rgba(124, 157, 223, 0.1);
				cursor: pointer;
			}
		}
		.tr {
			margin-bottom: 8px;
			height: 40px;
			display: flex;
			align-items: center;
			padding-left: 20px;
			.th {
				color: #79d1ff;
			}
			.th,
			.td {
				flex: 1;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				&:nth-of-type(1) {
					max-width: 80px;
				}
				&:nth-of-type(4) {
					min-width: 140px;
					padding-right: 20px;
				}
				&:nth-of-type(8) {
					min-width: 180px;
				}
			}
		}
	}
}
</style>
