<template>
	<div class="box-title">
		<img class="icon-title" src="@/assets/imgs/screen/icon-title.png" alt="" />
		{{ title }}
	</div>
</template>
<script>
export default {
	name: 'BsTitle',
	props: {
		title: {
			type: String,
		},
	},
}
</script>
<style scoped lang="scss">
.box-title {
	height: 60px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.16);
	position: relative;
	font-size: 18px;
	font-weight: 400;
	display: flex;
	align-items: center;
	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -1px;
		width: 5px;
		border: 1px solid #fff;
	}
	.icon-title {
		width: 37px;
		height: 37px;
		margin-right: 8px;
	}
	~ .box-title {
		margin-top: 40px;
	}
}
</style>
